<template>
  <div class="create-advert">
    <error :errors="advertsError" />
    <navigation-form />
  </div>
</template>

<script>
  import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
  import NavigationForm from "../../../components/forms/NavigationForm";
  import Error from "../../../partials/widgets/Error";
  import { mapGetters } from "vuex";
  export default {
    name: "index",
    components: { NavigationForm, Error },
    computed: {
      ...mapGetters(['advertsError'])
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "باشلاش تىزىملىكى باشقۇرۇش", route: "list" },
        { title: "يېڭىدىن قوشۇش" }
      ]);
    }
  }
</script>
