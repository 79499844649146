<template>
  <div class="navigation-form-container">
    <error :errors="navigationError" />
    <!-- <b-overlay :show="formBusy"> -->
      <portlet
        :title="portletTitle"
      >
        <template v-if="action === 'edit'" v-slot:toolbar>
          <b-button :to="{name: 'navigations.create'}" class="btn btn-label-success btn-sm border-0">
            يېڭىدىن قوشۇش
          </b-button>
        </template>
        <template v-slot:body>
          <b-form ref="permission" @submit.stop.prevent="onSubmit" :model="formData" @reset="onReset">
            <b-form-row>
              <b-col sm="6" md="2">
                <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
                  <b-form-checkbox id="is_show" switch v-model="formData.is_show"/>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="4">
                <b-form-group :state="validateState('client')" aria-describedby="client-live-feedback" id="input-group-3" label="تېرمىنال" label-for="client">
                  <b-form-radio-group id="target" v-model="formData.client" :options="clientOptions" buttons name="radios-btn-default" />
                  <b-form-invalid-feedback id="client-live-feedback">
                    تېرمىنال تۈرىنى تاللاڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-row>
              <b-col md="4" sm="6" >
                <b-form-group id="input-group-title" label="تىزىملىك ماۋزوسى" label-for="title" >
                  <b-form-input id="title" v-model="formData.title" :state="validateState('title')" type="text" aria-describedby="title-live-feedback" placeholder="تىزىملىك ماۋزوسىنى كىرگۈزۈڭ" />
                  <b-form-invalid-feedback id="title-live-feedback">
                    تىزملىك ماۋزوسىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="4">
                <b-form-group id="input-group-background" label="دېكوراتسىيە رەڭ نۇمۇرى" label-for="title-color">
                  <b-input-group>
                    <b-form-input :state="validateState('background')" aria-describedby="background-live-feedback" id="background" placeholder="سىنبەلگە دېكوراتسىيە رەڭگىنى تاللاڭ" style="direction: ltr" v-model="formData.background" />
                    <b-input-group-append>
                      <b-button class="d-flex align-items-center justify-content-center">
                        <colorPicker ref="colorPicker" v-model="formData.background || ''" @change="changeTitleColor" />
                      </b-button>
                    </b-input-group-append>
                    <b-form-invalid-feedback id="background-live-feedback">
                      دېكوراتسىيە رەڭگىنى تاللاڭ
                    </b-form-invalid-feedback>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="4">
                <b-form-group :state="validateState('target')" aria-describedby="target-live-feedback" id="input-group-2" label="تىزىملىك تۈرى" label-for="target">
                  <b-form-radio-group id="target" v-model="formData.target" @change="changeTarget" :options="targetOptions" buttons name="radios-btn-default" />
                  <b-form-invalid-feedback id="target-live-feedback">
                    تىزىملىك تۈرىنى تاللاڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col v-if="formData.target" sm="6" md="4">
                <template v-if="formData.target === 'category'">
                  <b-form-group label="تۈر تاللاڭ" >
                    <b-input-group>
                      <b-form-input :state="validateState('to')" aria-describedby="target-live-feedback" v-model="targetTitle" placeholder="سول تەرەپتىكى كونۇپكىنى بېسىپ تۈر تاللاڭ" readonly />
                      <b-input-group-append>
                        <b-button @click="showCategoryModal" class="d-flex justify-content-center align-content-center"> تاللاڭ </b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback id="target-live-feedback">
                        قوشماقچى بولغان تۈرنى تاللاڭ
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </template>
                <template v-else-if="formData.target === 'article'">
                  <b-form-group label="ئەسەر تاللاڭ" >
                    <b-input-group>
                      <b-form-input :state="validateState('to')" aria-describedby="target-live-feedback" v-model="targetTitle" placeholder="سول تەرەپتىكى كونۇپكىنى بېسىپ ئەسەر تاللاڭ" readonly />
                      <b-input-group-append>
                        <b-button @click="showArticleModal" class="d-flex justify-content-center align-content-center"> تاللاڭ </b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback id="target-live-feedback">
                        قوشماقچى بولغان ئەسەرنى تاللاڭ
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </template>
                <template v-else-if="formData.target === 'author'">
                  <b-form-group label="ئاپتور تاللاڭ" >
                    <b-input-group>
                      <b-form-input :state="validateState('to')" aria-describedby="target-live-feedback" v-model="targetTitle" placeholder="سول تەرەپتىكى كونۇپكىنى بېسىپ ئاپتور تاللاڭ" readonly />
                      <b-input-group-append>
                        <b-button @click="showAuthorModal" class="d-flex justify-content-center align-content-center"> تاللاڭ </b-button>
                      </b-input-group-append>
                      <b-form-invalid-feedback id="target-live-feedback">
                        قوشماقچى بولغان ئاپتورنى تاللاڭ
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </template>
                <template v-else-if="formData.target === 'mp'">
                  <b-form-group id="input-group-to" :label="toLabel">
                    <b-form-input style="direction: ltr" id="path" v-model="formData.to" :state="validateState('to')" type="text" aria-describedby="to-live-feedback" placeholder="ئەپچاق 页面路径 سىنى كىرگۈزۈڭ" />
                    <b-form-invalid-feedback id="title-live-feedback">
                      ئەپچاق 页面路径 سىنى كىرگۈزۈڭ
                    </b-form-invalid-feedback>
                  </b-form-group>
                </template>
                <template v-else-if="formData.target === 'external_link'">
                  <b-form-group id="input-group-link" :label="toLabel">
                    <b-form-input id="link" v-model="formData.to" :state="validateState('to')" type="text" aria-describedby="to-live-feedback" placeholder=" تور بەت ئادېرسىنى كىرگۈزۈڭ" />
                    <b-form-invalid-feedback id="title-live-feedback">
                      تور بەت ئادېرسىنى كىرگۈزۈڭ
                    </b-form-invalid-feedback>
                  </b-form-group>
                </template>
              </b-col>
              <b-col v-if="formData.target === 'mp'" sm="6" md="4">
                <b-form-group id="input-group-target-option" label="appid">
                  <b-form-input style="direction: ltr" id="appid" v-model="formData.target_option" :state="validateState('target_option')" type="text" aria-describedby="target_option-live-feedback" placeholder="ئەپچاق AppId سىنى كىرگۈزۈڭ" />
                  <b-form-invalid-feedback id="target_option-live-feedback">
                    ئەپچاق AppId سىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="4">
                <b-form-group id="input-group-9" label=" تەرتىبى" label-for="sort">
                  <b-form-input style="width: 100%;" id="sort" min="0" v-model="formData.sort" type="number" placeholder="تىزىملىك تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="4">
                <b-form-group :state="validateState('icon')" aria-describedby="target-live-feedback" id="input-group-icon" label="تىزىملىك سىنبەلگىسى" label-for="icon">
                  <image-uploader  :width="100" :height="100" @success="onUploadSuccess" accepted-file-types="image/png,image/gif" :img-url="oldImage" :upload-form="{strategy: 'navigation'}" />
                  <b-form-invalid-feedback id="target-live-feedback">
                    تىزىملىك سىنبەلگىسىنى تاللاڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-button id="submit" type="submit" variant="primary">
              ساقلاش
            </b-button>
            <b-button class="ml-2" type="reset" variant="danger">
              بىكار قىلىش
            </b-button>
          </b-form>
          <article-modal :state="articleModalState" @choose="onChooseArticle" @close="closeArticleModal" />
          <category-modal :state="categoryModalState" @choose="onChooseCategory" @close="closeCategoryModal" />
          <author-modal :state="authorModalState" @choose="onChooseAuthor" @close="closeAuthorModal" />
        </template>
      </portlet>
    <!-- </b-overlay> -->
  </div>
</template>

<script>
  import Portlet from "../../partials/content/Portlet";
  import Error from "../../partials/widgets/Error";
  import ImageUploader from "../../partials/layout/_ImageUploader";
  import ArticleModal from "../choseModal/ArticleModal";
  import CategoryModal from "../choseModal/CategoryModal";
  import AuthorModal from "../choseModal/AuthorModal";
  import navigationMixin from "../../../mixins/navigationMixin";
  import validateStateMixin from "../../../mixins/common/validateStateMixin";
  import { validationMixin } from "vuelidate";
  import { required, requiredIf } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import formBusyMixin from "@m@/common/formBusy.mixin";
  import { mapGetters } from "vuex";
  import vcolorpicker from "vcolorpicker";
  import Vue from "vue";
  Vue.use(vcolorpicker);
  export default {
    name: "NavigationForm",
    mixins: [ formBusyMixin, validateStateMixin, validationMixin, navigationMixin, sweetAlertMixin ],
    components: { Portlet, Error, ImageUploader, ArticleModal, CategoryModal, AuthorModal },
    props: {
      action: {
        type: String,
        default: 'create'
      }
    },
    validations(){
      return {
        formData: {
          title: { required },
          icon: { required },
          background: { required },
          target: { required },
          client: { required },
          to: { required },
          target_option: { required: requiredIf((formData)=>{
            return formData.target === 'mp';
            })}
        },
      }
    },
    computed: {
      ...mapGetters(['navigationError']),
      portletTitle(){
        if ( this.action === 'create' )
          return 'يېڭىدىن باشلاش تىزىملىكى قوشۇش';
        else if ( this.action === 'edit' )
          return 'باشلاش تىزىملىكى ئۇچۇرىنى تەھرىرلەش';
      },
      toLabel(){
        switch (this.formData.target) {
          case 'category':
            return 'ئەسەر تۈرىنى تاللاڭ';
          case 'article':
            return 'ئەسەر تاللاڭ';
          case 'author':
            return 'ئاپتور تاللاڭ';
          case 'mp':
            return 'ئەپچاق بەت ئادېرسى';
          case 'external_link':
            return 'سىرتقى ئۇلانما ئادېرسى';
        }
      },
      oldImage(){
        return this.action === 'edit' && this.formData.icon || '';
      },
    },
    mounted() {},
    created() {
      if (this.action === 'edit'){
        let { id } = this.$route.params;
        this.findNavigation(id)
                .then(response=>{
                  let { data } = response;
                  this.formData = data;
                  let { navigationable } = data;
                  navigationable = navigationable ? navigationable : {};
                  if ( navigationable.hasOwnProperty('id') ){
                    this.targetTitle = navigationable.title ? navigationable.title : navigationable.name;
                    this.formData.to = navigationable.id;
                  }
                }).catch((res)=>{
                  console.log(res)
          this.$router.replace({name: 'navigations.list'});
        })
      }
    },
    data(){
      return {
        formData: {
          title: '',
          color: null,
          background: null,
          target: '',
          client: '',
          to: '',
          target_option: null,
          sort: '',
          icon: null,
          is_show: true
        },
        targetOptions: [
          { text: 'تۈر', value: 'category' },
          { text: 'ئەسەر', value: 'article' },
          { text: 'ئاپتور', value: 'author' },
          { text: 'ئەپچاق', value: 'mp' },
          { text: 'سىرتقى ئۇلانما', value: 'external_link' },
        ],
        clientOptions: [
          { text: 'بارلىق', value: 'all' },
          { text: 'ئەپچاق', value: 'mini' },
          { text: 'ئەپ', value: 'app' },
          { text: 'توربەت', value: 'web' },
        ],
        articleModalState: false,
        categoryModalState: false,
        authorModalState: false,
        targetTitle: ''
      }
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning('تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ');
        }
        if (this.action === 'create'){
          this.createNavigation(this.formData)
                  .then(()=>{
                    return this.$router.replace({name: "navigations.list"});
                  })
        }else if ( this.action === 'edit' ){
          this.updateNavigation(this.formData)
            .then(()=>{this.success('تەھرىرلەش مۇۋاپىقىيەتلىك بولدى!');})
        }
      },
      onReset(){
        return this.$router.replace({ name: "navigations.list" });
      },
      changeTitleColor(e){
        this.formData.background = e;
      },
      changeTarget(e){
        this.formData.to = null;
        this.targetTitle = null;
      },

      onUploadSuccess(response){
        // console.log(response);
        this.formData.icon = response.path;
        // this.formData.preview = response.url;
      },
      toggleArticleModal(){
        this.articleModalState = ! this.articleModalState;
      },
      showArticleModal(){
        this.toggleArticleModal();
      },
      closeArticleModal(){
        this.articleModalState = false;
      },
      onChooseArticle(e){
        this.targetTitle = e.title;
        this.formData.to = e.id;
        this.$nextTick(()=>{
          this.toggleArticleModal();
        })
      },
      toggleAuthorModal(){
        this.authorModalState = ! this.authorModalState;
      },
      toggleCategoryModal(){
        this.categoryModalState = ! this.categoryModalState;
      },
      showCategoryModal(){
        this.toggleCategoryModal();
      },
      closeCategoryModal(){
        this.categoryModalState = false;
      },
      closeAuthorModal(){
        this.categoryModalState = false;
      },
      onChooseCategory(e){
        this.targetTitle = e.title;
        this.formData.to = e.id;
        this.$nextTick(()=>{
          this.toggleCategoryModal();
        })
      },
      onChooseAuthor(e){
        let { title, id } = e;
        this.targetTitle = title;
        this.formData.to = id;
        this.$nextTick(()=>{
          this.toggleAuthorModal();
        })
      },
      showAuthorModal(){
        this.toggleAuthorModal()
      }
    }
  }
</script>
